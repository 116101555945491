<template>
  <div class="home">
    <header class="header1">
      <canvas id="canvas"> </canvas>
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner3.png"
        alt=""
      />

      <h4>BRING PEOPLE TOGETHER THROUGH <br>  &nbsp;BRAND EMPOWERMENT.</h4>
    </header>
    <header class="header2">
      <canvas id="canvas"> </canvas>
      <img
        class="header-bg"
        src="https://prod.oss.netopstec.com/net-home-banner-h5.png"
        alt=""
      />

      <h4>网营科技</h4> 
      <!-- <h3>BOOST BRANDS &nbsp;CONNECT US
      </h3> -->
    </header>
    <div ref="introduce" v-if="IsPC()" class="introduce">
      <video
        id="video"
        v-show="showVideo"
        class="video"
        preload
        controls
        src="https://prod.oss.netopstec.com/wysp1.mp4"
      ></video>
      <img
        src="@/assets/close.png"
        v-show="showVideo"
        @click="stopVideo"
        style="
          position: absolute;
          z-index: 10000;
          right: 132px;
          width: 40px;
          top: 208px;
        "
        alt=""
      />
      <img
        class="introduce-bg"
        src="https://prod.oss.netopstec.com/net-introduce-bg2-8-8.png"
        alt=""
      />
      <div class="text">
        <div class="title">
          <p>关于网营</p>
          <div></div>
          <span>NETOPS</span>
        </div>
        <div class="body">
          网营科技创立于2009年，公司总部位于杭州，截至2023年3月31日，公司全职员工总数为613人。作为一家为全球知名品牌和新兴品牌提供全链路全渠道电商服务的公司，网营以"注力品牌，连接你我"为使命，聚焦母婴、宠物、食品保健、美妆个护等四大核心领域。
          <div class="br"></div>
          通过十余年电商行业实战经验，网营科技为大宠爱、美素佳儿、爱敬、歌帝梵、布朗博士、德运等超300家国内外一线品牌提供电商运营、品牌营销、消费者客服、仓储配送以及数据及技术解决方案，全方位助力品牌实现电商业务增长。
        </div>
        <div class="foot">
          <!-- <div @click="playVideo" class="btn">
            <span>观看宣传片</span>
            <div class="jt"></div>
          </div> -->
          <div class="list">
            <img
              src="https://prod.oss.netopstec.com/introduce-icon11.jpg"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/introduce-icon22.jpg"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/introduce-icon33.jpg"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/introduce-icon44.jpg"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/introduce-icon55.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div ref="introduce" v-else class="introduce">
      <video
        id="video"
        v-show="showVideo"
        class="video"
        preload
        controls
        src="https://prod.oss.netopstec.com/wysp1.mp4"
      ></video>
      <img
        src="@/assets/close.png"
        v-show="showVideo"
        @click="stopVideo"
        style="
          position: absolute;
          z-index: 10000;
          right: 2%;
          width: 30px;
          top: 27%;
        "
        alt=""
      />
      <img
        class="introduce-bg"
        src="https://prod.oss.netopstec.com/net-introduce-bg2-h5-8-8.png"
        alt=""
      />
      <div class="text">
        <div class="title">
          <p>关于网营</p>
          <div></div>
          <!-- <span>NETOPS</span> -->
        </div>
        <div class="body">
          网营科技创立于2009年，公司总部位于杭州，截至2023年3月31日，公司全职员工总数为613人。作为一家为全球知名品牌和新兴品牌提供全链路全渠道电商服务的公司，网营以"注力品牌，连接你我"为使命，聚焦母婴、宠物、食品保健、美妆个护等四大核心领域。
          <div class="br"></div>
          通过十余年电商行业实战经验，网营科技为大宠爱、美素佳儿、爱敬、歌帝梵、布朗博士、德运等超300家国内外一线品牌提供电商运营、品牌营销、消费者客服、仓储配送以及数据及技术解决方案，全方位助力品牌实现电商业务增长。
        </div>
        <div class="foot">
          <!-- <div @click="playVideo" class="btn">
            <span>观看宣传片</span>
            <div class="jt"></div>
          </div> -->
          <div class="list">
            <img
              src="https://prod.oss.netopstec.com/home-icon1.png"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/home-icon2.png"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/home-icon3.png"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/home-icon4.png"
              alt=""
            />
            <img
              src="https://prod.oss.netopstec.com/home-icon5.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div ref="achievement" class="achievement">
      <img
        class="achievement-bg"
        src="https://prod.oss.netopstec.com/achievement-bg3.png"
        alt=""
      />
      <div class="main">
        <div>
          <img
            src="https://prod.oss.netopstec.com/net-achievement-logo1.png"
            alt=""
          />
          <p>首批</p>
          <p>杭州电商服务企业之一</p>
        </div>
        <div>
          <img
            src="https://prod.oss.netopstec.com/net-achievement-logo2.png"
            alt=""
          />
          <p>见证和参与阿里</p>
          <p>第一个“双11”</p>
        </div>
        <div>
          <img
            src="https://prod.oss.netopstec.com/net-achievement-logo3.png"
            alt=""
          />
          <p>阿里签约合作伙伴</p>
          <p>第一批</p>
        </div>
        <div>
          <img
            src="https://prod.oss.netopstec.com/net-achievement-logo4.png"
            alt=""
          />
          <p>首批</p>
          <p>数据银行成员</p>
        </div>
        <div>
          <img
            src="https://prod.oss.netopstec.com/net-achievement-logo5.png"
            alt=""
          />
          <p>首批</p>
          <p>抖音金牌服务商</p>
        </div>
        <div>
          <img
            src="https://prod.oss.netopstec.com/net-achievement-logo6.png"
            alt=""
          />
          <p>首批</p>
          <p>淘宝内容合作伙伴</p>
        </div>
      </div>
    </div>
    <div ref="box_titlea" class="box_title">
      <h6>
        服务概览&nbsp;&nbsp;<span>电商服务</span>
        <p></p>
      </h6>
    </div>
    <div ref="service" v-if="IsPC()" class="service1">
      <img src="https://prod.oss.netopstec.com/net-service-bg44.jpg" alt="" />
      <div class="body">
        <div class="left">
          <div class="circular circular1">
            <span>全方位</span>
          </div>
          <div class="circular circular2">
            <span>全渠道</span>
          </div>
          <div class="circular circular3">
            <span>多品类</span>
          </div>
          <div class="circular circular4">
            <span>全数据</span>
          </div>
          <div class="circular circular5">
            <span>全链路</span>
          </div>
          <div class="mid">全域营销</div>
        </div>
        <div class="right">
          <div class="title">电商全域电商服务</div>
          <div class="gang"></div>
          <p>
            致力于打造行业领先的多品类、全方位、全渠道、全链路、全数据的电商全域营销管理服务公司，为品牌客户实现从0到1线上营销飞跃。
          </p>
        </div>
      </div>
    </div>
    <div ref="service" v-else class="service1">
      <img src="https://prod.oss.netopstec.com/net-service-bg45-h5.jpg" alt="" />
      <div class="body">
        <div class="left">
          <div class="circular circular1">
            <span>全方位</span>
          </div>
          <div class="circular circular2">
            <span>全渠道</span>
          </div>
          <div class="circular circular3">
            <span>多品类</span>
          </div>
          <div class="circular circular4">
            <span>全数据</span>
          </div>
          <div class="circular circular5">
            <span>全链路</span>
          </div>
          <div class="mid">全域营销</div>
        </div>
        <div class="right">
          <div class="title">电商全域电商服务</div>
          <div class="gang"></div>
          <p>
            致力于打造行业领先的多品类、全方位、全渠道、全链路、全数据的电商全域营销管理服务公司，为品牌客户实现从0到1线上营销飞跃。
          </p>
        </div>
      </div>
    </div>
    
   <div ref="box_title2" class="box_title">
      <h6>合作品牌</h6>
    </div>
    <div id="brand_rotation" v-if="IsPC()" ref="brand_rotation" class="brand_rotation" style="height:250px">
      <!-- <img
        v-if = 'showleftButton'
        @click="handleBrand(0)"
        class="left"
        src="https://prod.oss.netopstec.com/net-jt-big.png"
        alt=""
      />
      <img
        v-if = 'showrightButton'
        @click="handleBrand(1)"
        class="right"
        src="https://prod.oss.netopstec.com/net-jt2-big.png"
        alt=""
      /> -->
      <div class="box" ref="boxBrand">
        <div ref="brand" v-if="IsPC()" class="brand brandLogo">
          <div v-for="(item, key) in imgList"  style="" :key="key">
            <img     
              ref="brandChildren"         
              v-for="items in item"
              :key="items.id"
              :src="getImgUrl(items.path)"
              alt=""
              style="opacity: 1;"
              id="imgStyleMedia"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 手机端适配 -->
    <div id="brand_rotation" v-else ref="brand_rotation" class="brand_rotation">
      <!-- <img
        v-if = 'showleftButton'
        @click="handleBrand(0)"
        class="left"
        src="https://prod.oss.netopstec.com/net-jt-big.png"
        alt=""
      />
      <img
        v-if = 'showrightButton'
        @click="handleBrand(1)"
        class="right"
        src="https://prod.oss.netopstec.com/net-jt2-big.png"
        alt=""
      /> -->
      <div class="box" ref="boxBrand">
        <div ref="brand" class="brand brandLogo">
          <div v-for="(item, key) in imgList_h5"  style="" :key="key">
            <img     
              ref="brandChildren"         
              v-for="items in item"
              :key="items.id"
              :src="getImgUrl(items.path)"
              alt=""
              style="height: 126px; opacity: 1;"
            />
          </div>
        </div>
      </div>
    </div>
    
   

    <div ref="box_title3" class="box_title">
      <h6>案例展示</h6>
    </div>
    <div ref="case" v-if="IsPC()" class="case">
      <div class="Viewing_area">
        <div ref="Theslider" class="Theslider">
          <div class="brander">
            <img
              @click="goCaseShows(1)"
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-meisu-bg.png"
              alt=""
            />
            <div class="body">
              <div class="top">
                <img
                  src="https://prod.oss.netopstec.com/net-meisu-logo.png"
                  alt=""
                />
                <div @click="goCaseShows(1)" class="btn">
                  <span>浏览详情</span>
                  <img
                    src="https://prod.oss.netopstec.com/net-icon-jt.png"
                    alt=""
                  />
                </div>
              </div>
              <p>旗舰店从0到1，两年销量超亿元</p>
            </div>
          </div>
          <div class="brander">
            <img
              @click="goCaseShows(2)"
              class="bg"
              src="https://prod.oss.netopstec.com/net-dachunai-bg.jpg"
              alt=""
            />
            <div class="body">
              <div class="top">
                <img
                  src="https://prod.oss.netopstec.com/net-dachunai-logo.png"
                  alt=""
                />
                <div @click="goCaseShows(2)" class="btn">
                  <span>浏览详情</span>
                  <img
                    src="https://prod.oss.netopstec.com/net-icon-jt.png"
                    alt=""
                  />
                </div>
              </div>
              <p>敏捷发展，一年跻身行业TOP2</p>
            </div>
          </div>
          <div class="brander">
            <img
              @click="goCaseShows(3)"
              class="bg"
              src="https://prod.oss.netopstec.com/net-godiva-bg.png"
              alt=""
            />
            <div class="body">
              <div class="top">
                <img
                  src="https://prod.oss.netopstec.com/net-godiva-logo.png"
                  alt=""
                />
                <div @click="goCaseShows(3)" class="btn">
                  <span>浏览详情</span>
                  <img
                    src="https://prod.oss.netopstec.com/net-icon-jt.png"
                    alt=""
                  />
                </div>
              </div>
              <p>玩转流量 助力品牌霸榜</p>
            </div>
          </div>
          <div class="brander">
            <img
              @click="goCaseShows(1)"
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-meisu-bg.png"
              alt=""
            />
            <div class="body">
              <div class="top">
                <img
                  src="https://prod.oss.netopstec.com/net-meisu-logo.png"
                  alt=""
                />
                <div @click="goCaseShows(1)" class="btn">
                  <span>浏览详情</span>
                  <img
                    src="https://prod.oss.netopstec.com/net-icon-jt.png"
                    alt=""
                  />
                </div>
              </div>
              <p>旗舰店从0到1，两年销量超亿元</p>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div
          @click="handleCaseIndex(1)"
          :class="caseIndex == 1 || caseIndex == 4 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(2)"
          :class="caseIndex == 2 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(3)"
          :class="caseIndex == 3 ? 'dian red' : 'dian'"
        ></div>
      </div>
      <img
        class="gdxl"
        @click="handleCaseIndex(false)"
        src="@/assets/arrow-down-normal.png"
        alt=""
      />
    </div>
    <div ref="case" id="case" v-else class="case">
      <div class="Viewing_area">
        <div ref="Theslider" class="Theslider">
          <div class="brander">
            <img
            @click="goCaseShows(1)"
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-meisu-bg-h5.png"
              alt=""
            />
            <div class="body">
              <img
                class="top"
                src="https://prod.oss.netopstec.com/net-meisu-logo.png"
                alt=""
              />
              <p>旗舰店从0到1，两年销量超亿元</p>
              <div @click="goCaseShows(1)" class="btn">
                <span>浏览详情</span>
                <img
                  src="https://prod.oss.netopstec.com/net-icon-jt.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="brander">
            <img
            @click="goCaseShows(2)"
              class="bg"
              src="https://prod.oss.netopstec.com/net-dachunai-bg-h5.png"
              alt=""
            />
            <div class="body">
              <img
                class="top"
                src="https://prod.oss.netopstec.com/net-dachunai-logo.png"
                alt=""
              />
              <p>敏捷发展，一年跻身行业TOP2</p>
              <div @click="goCaseShows(2)" class="btn">
                <span>浏览详情</span>
                <img
                  src="https://prod.oss.netopstec.com/net-icon-jt.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="brander">
            <img
            @click="goCaseShows(3)"
              class="bg"
              src="https://prod.oss.netopstec.com/net-godiva-bg-h5.png"
              alt=""
            />
            <div class="body">
              <img
                class="top"
                src="https://prod.oss.netopstec.com/net-godiva-logo.png"
                alt=""
              />
              <p>玩转流量 助力品牌霸榜</p>
              <div @click="goCaseShows(3)" class="btn">
                <span>浏览详情</span>
                <img
                  src="https://prod.oss.netopstec.com/net-icon-jt.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="brander">
            <img
            @click="goCaseShows(1)"
              class="bg"
              ref="caseHeight"
              src="https://prod.oss.netopstec.com/net-meisu-bg-h5.png"
              alt=""
            />
            <div class="body">
              <img
                class="top"
                src="https://prod.oss.netopstec.com/net-meisu-logo.png"
                alt=""
              />
              <p>旗舰店从0到1，两年销量超亿元</p>
              <div @click="goCaseShows(1)" class="btn">
                <span>浏览详情</span>
                <img
                  src="https://prod.oss.netopstec.com/net-icon-jt.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div
          @click="handleCaseIndex(1)"
          :class="caseIndex == 1 || caseIndex == 4 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(2)"
          :class="caseIndex == 2 ? 'dian red' : 'dian'"
        ></div>
        <div
          @click="handleCaseIndex(3)"
          :class="caseIndex == 3 ? 'dian red' : 'dian'"
        ></div>
      </div>
      <!-- <img
        class="gdxl"
        @click="handleCaseIndex(false)"
        src="https://prod.oss.netopstec.com/net-gdxl1.png"
        alt=""
      /> -->
    </div>
    <div ref="box_title4" class="box_title">
      <h6>最新动态</h6>
    </div>
    <div id="dynamic" ref="dynamic" class="dynamic">
      <img
      v-if=" dynamicIndex== 1"
        @click="handleDynamic(0)"
        class="left"
        src="https://prod.oss.netopstec.com/net-jt-big.png"
        alt=""
      />
      <img
       v-if=" dynamicIndex== 0"
        @click="handleDynamic(1)"
        class="right"
        src="https://prod.oss.netopstec.com/net-jt2-big.png"
        alt=""
      />
      <div class="box">
        <ul ref="dynamic_ul">
          <li
            v-for="item in DynamicList"
            @click="openModel(item)"
            :key="item.id"
            ref="li"
          >
            <img
              :src="`https://img.netopstec.com/website` + item.imgPath"
              alt=""
            />
            <p>{{ item.title }}</p>
          </li>

          <li @click="$router.push('/newDynamic')" class="more">
            <p>
              查看更多
              <img src="https://prod.oss.netopstec.com/net-hei-jt.png" alt="" />
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div ref="box_title5" class="box_title">
      <h6>联系我们</h6>
    </div>
    <div class="foot" v-if="IsPC()" ref="foot">
      <img
        class="bg"
        src="https://prod.oss.netopstec.com/net-foot-bg-8-8.png"
        alt=""
      />
      <div class="body">
        <img
          src="https://prod.oss.netopstec.com/net-logo.png"
          alt=""
          class="logo"
        />
        <div class="text">
          <div class="left">
            <p>官方微信</p>
            <img
              src="https://prod.oss.netopstec.com/net-foot-erweima2.jpg"
              alt=""
              class="erweima"
            />
            <h5>联系我们</h5>
            <li>
              <img
                src="https://prod.oss.netopstec.com/net-foot-icon1.png"
                alt=""
              />
              <span>{{ ContactInfo.otherContacts.companyFax }}</span>
            </li>
            <li>
              <img
                src="https://prod.oss.netopstec.com/net-foot-icon4.png"
                alt=""
              />
              <span>{{ ContactInfo.otherContacts.companyPhone }}</span>
            </li>
            <li>
              <img
                src="https://prod.oss.netopstec.com/net-foot-icon5.png"
                alt=""
              />
              <span>{{ ContactInfo.otherContacts.addressZh }}</span>
            </li>
          </div>
          <div class="right">
            <div
              v-for="(item, index) in ContactInfo.contactsList"
              :key="index"
              class="box"
            >
              <h5>{{ item.deptNameZh }}</h5>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon3.png"
                  alt=""
                />
                <span>{{ item.phone }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon2.png"
                  alt=""
                />
                <span>{{ item.email }}</span>
              </li>
            </div>
          </div>
        </div>
        <div class="footer" v-if="!IsPc">
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>
      </div>
    </div>
    <div class="foot" v-else ref="foot">
      <img
        class="bg"
        src="https://prod.oss.netopstec.com/net-foot-bg-h5-8-9.png"
        alt=""
      />
      <div class="body">
        <div class="text">
          <div class="right">
            <div
              v-for="(item, index) in ContactInfo.contactsList"
              :key="index"
              class="box"
            >
              <h5>{{ item.deptNameZh }}</h5>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon1.png"
                  alt=""
                />
                <span>{{ item.nameZh }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon3.png"
                  alt=""
                />
                <span>{{ item.phone }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon2.png"
                  alt=""
                />
                <span>{{ item.email }}</span>
              </li>
            </div>
            <div class="box">
              <h5>联系我们</h5>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon1.png"
                  alt=""
                />
                <span>{{ ContactInfo.otherContacts.companyPhone }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon4.png"
                  alt=""
                />
                <span>{{ ContactInfo.otherContacts.companyFax }}</span>
              </li>
              <li>
                <img
                  src="https://prod.oss.netopstec.com/net-foot-icon5.png"
                  alt=""
                />
                <span>{{ ContactInfo.otherContacts.addressZh }}</span>
              </li>
            </div>
          </div>
          <div class="left">
            <p>官方微信</p>
            <img
              src="https://prod.oss.netopstec.com/net-foot-erweima2.jpg"
              alt=""
              class="erweima"
            />
          </div>
        </div>
        <div class="footer" v-if="!IsPc">
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>
      </div>
    </div>
    <modelbox :showData="selectData" :show="showModel" :clone="handleClone">
    </modelbox>
  </div>
</template>
<script>
import { get, post } from "@/utils/http";
import modelbox from "../components/modelbox.vue";
export default {
  components: { modelbox },
  data() {
    return {
      ContactInfo: {},
      caseIndex: 1,
      dynamicIndex:0,
      DynamicList: [],
      caseStatus: false, //轮播图函数只执行一次的控制器
      showVideo: false,
      showModel: false,
      selectData: {},
      caseTimer: -1,
      imgList: [],
      imgList_h5:[],
      leftDom: 0,
      showleftButton: false,
      showrightButton: true,
    };
  },
  mounted() {
     get('/open/getCategoryImageForReception',{
      categoryId: 10,
      type: 0,
      terminalType: 0
    }).then(res => {
      this.imgList = this.arrayResolve(2, res.categoryBrandLogInfoList);
      this.imgList_h5 = this.arrayResolve(4, res.categoryBrandLogInfoList);
    })
    window.addEventListener("scroll", this.handleScroll);
    if (this.IsPC()) {
      this.handleCanvas();
    } else {
      setTimeout(() => {
        this.caseMove();
        this.dynamicMove();
        this.brandMove();
      }, 500);
    }
    this.selectContactInformation();
    this.getDynamic();

    if (this.$route.query.showVideo) {
      this.playVideo();
    }
  },
  methods: {
    getImgUrl(item){
      let url = 'https://img.netopstec.com/website'+item;
      return url;
    },
    arrayResolve(n, arr) {
      let result = [];
      for (let i = 0; i < arr.length; i += n) {
          result.push(arr.slice(i, i + n));
      }
      return result
    },
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    brandMove() {
      // 合作品牌 滑动事件
      let that = this;
      let branddom = document.getElementById("brand_rotation");
      branddom.ontouchstart = function (e) {
        var one = e.touches[0].pageX;
        that.handleCancleCase();
        branddom.ontouchend = function (e) {
          var two = e.changedTouches[0].pageX;
          if (one > two) {
            // console.log('向左')
            if(that.showrightButton) that.handleBrand(1)
          } else {
            // console.log('向右')
            if(that.showleftButton) that.handleBrand(0)
          }
        };
      };
    },
    caseMove() {
      //案例展示轮播图 滑过事件
      let that = this;
      let casedom = document.getElementById("case");
      casedom.ontouchstart = function (e) {
        var one = e.touches[0].pageX;
        that.handleCancleCase();
        casedom.ontouchend = function (e) {
          var two = e.changedTouches[0].pageX;
          if (one > two) {
            // console.log('向左')
            that.handleCaseIndex(false);
          } else {
            // console.log('向右')
            that.handleCaseIndex(true);
          }
          const ThesliderDom = that.$refs["Theslider"];
          ThesliderDom.style.transform = `translateX(0)`;
          that.handleCase();
        };
        casedom.ontouchmove = function (e) {
          var two = e.touches[0].pageX;
          const ThesliderDom = that.$refs["Theslider"];
          ThesliderDom.style.transform = `translateX(${two - one}px)`;
        };
      };
    },
    dynamicMove() {
      //最新动态划过
      let that = this;
      let dynamicdom = document.getElementById("dynamic");
      dynamicdom.ontouchstart = function (e) {
        var one = e.touches[0].pageX;
        dynamicdom.ontouchend = function (e) {
          var two = e.changedTouches[0].pageX;
          if (one > two) {
            // console.log('向左')
            that.handleDynamic(1);
          } else {
            that.handleDynamic(0);
            // console.log('向右')
          }
          const ThesliderDom = that.$refs["dynamic_ul"];
          ThesliderDom.style.transform = `translateX(0)`;
        };
        dynamicdom.ontouchmove = function (e) {
          var two = e.touches[0].pageX;
          const ThesliderDom = that.$refs["dynamic_ul"];
          ThesliderDom.style.transform = `translateX(${two - one}px)`;
        };
      };
    },
    handleClone() {
      this.showModel = false;
    },
    openModel(item) {
      this.selectData = item;
      this.showModel = true;
    },
    selectContactInformation() {
      get("/open/selectContactInformation", {
        terminalType: 0,
      }).then((res) => {
        this.ContactInfo = res;
      });
    },
    playVideo() {
      this.showVideo = true;
      setTimeout(() => {
        window.document.querySelector("#video").play();
      }, 300);
    },
    stopVideo() {
      this.showVideo = false;
      setTimeout(() => {
        window.document.querySelector("#video").pause();
      }, 300);
    },
    getDynamic() {
      get("/recent-news", {
        pageNum: 1,
        pageSize: 3,
      }).then((res) => {
        this.DynamicList = res.data.list;
        this.$beforeupdate;
        if (this.$route.query.id) {
          this.DynamicList.map((item) => {
            if (item.id == this.$route.query.id) {
              let top = this.$refs.box_title4.offsetTop;
              window.pageYOffset = top;
              document.documentElement.scrollTop = top;
              document.body.scrollTop = top;
              this.openModel(item);
            }
          });
        }
      });
    },
    handleCanvas() {
      //
      // 可调参数
      // var BACKGROUND_COLOR = "rgba(0,43,54,1)"; // 背景颜色
      var POINT_NUM = 50; // 星星数目
      var POINT_COLOR = "rgba(255,255,255,1)"; // 点的颜色
      var LINE_LENGTH = 10000; // 点之间连线长度(的平方)

      // 创建背景画布
      var cvs = document.createElement("canvas");
      cvs.width = window.innerWidth;
      cvs.height = window.innerHeight;
      cvs.className = "cani";
      cvs.style.cssText =
        "\
    position:absolute;\
    top:0px;\
    left:0px;\
    z-index:-1;\
    opacity:1.0;\
    ";
      document.body.appendChild(cvs);

      var ctx = cvs.getContext("2d");

      var startTime = new Date().getTime();

      //随机数函数
      function randomInt(min, max) {
        return Math.floor((max - min + 1) * Math.random() + min);
      }

      function randomFloat(min, max) {
        return (max - min) * Math.random() + min;
      }

      //构造点类
      function Point() {
        this.x = randomFloat(0, cvs.width);
        this.y = randomFloat(0, cvs.height);

        var speed = randomFloat(0.2, 1);
        var angle = randomFloat(0, 2 * Math.PI);

        this.dx = Math.sin(angle) * speed;
        this.dy = Math.cos(angle) * speed;

        this.r = 1.2;

        this.color = POINT_COLOR;
      }

      Point.prototype.move = function () {
        this.x += this.dx;
        if (this.x < 0) {
          this.x = 0;
          this.dx = -this.dx;
        } else if (this.x > cvs.width) {
          this.x = cvs.width;
          this.dx = -this.dx;
        }
        this.y += this.dy;
        if (this.y < 0) {
          this.y = 0;
          this.dy = -this.dy;
        } else if (this.y > cvs.height) {
          this.y = cvs.height;
          this.dy = -this.dy;
        }
      };

      Point.prototype.draw = function () {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.r, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
      };

      var points = [];

      function initPoints(num) {
        for (var i = 0; i < num; ++i) {
          points.push(new Point());
        }
      }

      var p0 = new Point(); //鼠标
      p0.dx = p0.dy = 0;
      var degree = 2.5;
      document.onmousemove = function (ev) {
        p0.x = ev.clientX;
        p0.y = ev.clientY;
      };
      document.onmousedown = function (ev) {
        degree = 5.0;
        p0.x = ev.clientX;
        p0.y = ev.clientY;
      };
      document.onmouseup = function (ev) {
        degree = 2.5;
        p0.x = ev.clientX;
        p0.y = ev.clientY;
      };
      window.onmouseout = function () {
        p0.x = null;
        p0.y = null;
      };

      function drawLine(p1, p2, deg) {
        var dx = p1.x - p2.x;
        var dy = p1.y - p2.y;
        var dis2 = dx * dx + dy * dy;
        if (dis2 < 2 * LINE_LENGTH) {
          if (dis2 > LINE_LENGTH) {
            if (p1 === p0) {
              p2.x += dx * 0.03;
              p2.y += dy * 0.03;
            } else return;
          }
          var t = (1.05 - dis2 / LINE_LENGTH) * 0.2 * deg;
          ctx.strokeStyle = "rgba(255,255,255," + t + ")";
          ctx.beginPath();
          ctx.lineWidth = 1.5;
          ctx.moveTo(p1.x, p1.y);
          ctx.lineTo(p2.x, p2.y);
          ctx.closePath();
          ctx.stroke();
        }
        return;
      }

      //绘制每一帧
      function drawFrame() {
        cvs.width = window.innerWidth;
        cvs.height = window.innerHeight;

        ctx.fillStyle = "rgba(255, 255, 255, 0)";
        ctx.fillRect(0, 0, cvs.width, cvs.height);

        var arr = p0.x == null ? points : [p0].concat(points);
        for (var i = 0; i < arr.length; ++i) {
          for (var j = i + 1; j < arr.length; ++j) {
            drawLine(arr[i], arr[j], 1.0);
          }
          arr[i].draw();
          arr[i].move();
        }

        window.requestAnimationFrame(drawFrame);
      }

      initPoints(POINT_NUM);
      drawFrame();
    },
    handleCase() {
      //案例展示计时器
      this.caseTimer = setInterval(() => {
        if (this.caseIndex >= 4) {
          this.caseIndex = 1;
          setTimeout(() => {
            this.caseIndex += 1;
          }, 100);
        } else {
          this.caseIndex += 1;
        }
      }, 5000);
    },
    handleCancleCase() {
      clearInterval(this.caseTimer);
      this.caseTimer = -1;
    },
    handleCaseIndex(num) {
      //案例轮播图
      if (typeof num == Number) {
        this.caseIndex = num;
      } else if (!num) {
        if (this.caseIndex == 4) {
          this.caseIndex = 1;
          setTimeout(() => {
            this.caseIndex += 1;
          }, 300);
        } else {
          this.caseIndex += 1;
        }
      } else {
        if (this.caseIndex == 1) {
          this.caseIndex = 4;
          setTimeout(() => {
            this.caseIndex -= 1;
          }, 300);
        } else {
          this.caseIndex -= 1;
        }
      }
    },
    handleDynamic(num) {
      //最新动态切换
      this.dynamicIndex = num
      if (num == 1) {
        this.$refs.dynamic_ul.style.left =
          "-" + (this.$refs.li[0].scrollWidth * 1.5 + 100) + "px";
      } else {
        this.$refs.dynamic_ul.style.left = "0";
      }
    },

    handleBrand(num) {
      //品牌切换
      if (num == 1) {
        this.leftDom += this.$refs.boxBrand.clientWidth;
        this.$refs.brand.style.left = "-" + (this.leftDom ) + "px";
        let width = this.$refs.boxBrand.clientWidth - this.$refs.brand.style.left.replace('px', '')
        if(this.$refs.brand.style.left == 0) {
          this.showleftButton = false;
        }else{
          this.showleftButton = true;
        }
        if( width > this.$refs.brand.clientWidth) {
          this.showrightButton = false;
        }else{
          this.showrightButton = true
        }
      }  else {
        this.leftDom -= this.$refs.boxBrand.clientWidth;
        this.$refs.brand.style.left = "-" + (this.leftDom ) + "px";
        let width = this.$refs.boxBrand.clientWidth - this.$refs.brand.style.left.replace('px', '')
        if(this.$refs.brand.style.left == '0px') {
          this.showleftButton = false;
        }else{
          this.showleftButton = true;
        }
        if( width > this.$refs.brand.clientWidth) {
          this.showrightButton = false;
        }else{
          this.showrightButton = true
        }
      }
    },

    handleScroll(e) {
      //滚动事件
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        scrollTop >=
        this.$refs.introduce.offsetTop - this.$refs.introduce.offsetHeight * 0.7
      ) {
        this.$refs.introduce.className = "introduce introduce_ini";
      }

      if (
        scrollTop >=
        this.$refs.achievement.offsetTop -
          this.$refs.achievement.offsetHeight * 0.7
      ) {
        this.$refs.achievement.className = "achievement achievement_ini";
      }

      if (
        scrollTop >=
        this.$refs.box_titlea.offsetTop -
          this.$refs.box_titlea.offsetHeight * 1.7
      ) {
        this.$refs.box_titlea.className = "box_title box_titlea_ini";
        this.$refs.service.className = "service service_ini";
      }
      if (
        scrollTop >=
        this.$refs.box_title2.offsetTop -
          this.$refs.box_title2.offsetHeight * 1.7
      ) {
        this.$refs.box_title2.className = "box_title box_title2_ini";
        // this.$refs.brand.className = "brand brand_ini";
      }
      if (
        scrollTop >=
        this.$refs.box_title3.offsetTop -
          this.$refs.box_title3.offsetHeight * 1.7
      ) {
        this.$refs.box_title3.className = "box_title box_title3_ini";
        this.$refs.case.className = "case case_ini";
        if (!this.caseStatus) {
          this.handleCase();
        }
        this.caseStatus = true;
      }
      if (
        scrollTop >=
        this.$refs.box_title4.offsetTop -
          this.$refs.box_title4.offsetHeight * 1.7
      ) {
        this.$refs.box_title4.className = "box_title box_title4_ini";
        this.$refs.dynamic.className = "dynamic dynamic_ini";
      }
      if (
        scrollTop >=
        this.$refs.box_title5.offsetTop -
          this.$refs.box_title5.offsetHeight * 1.7
      ) {
        this.$refs.box_title5.className = "box_title box_title5_ini";
        this.$refs.foot.className = "foot foot_ini";
      }
      //
    },
    goCaseShows(index) {
      let url = this.$router.resolve({
        path: "/caseShows",
        query: {
          index: index,
        },
      })
      // console.log(url)
      window.open(url.href,'_blank')
 
    },
  },
  watch: {
    caseIndex(news, old) {
      if (this.IsPC()) {
        if (news == 1) {
          this.$refs.Theslider.className = "Theslider Thesliders";
          this.$refs.Theslider.style.top = "0px";
          setTimeout(() => {
            this.$refs.Theslider.className = "Theslider";
          }, 100);
        } else {
          this.$refs.Theslider.style.top =
            -this.$refs.caseHeight.offsetHeight * (news - 1) + "px";
        }
      } else {
        if (news == 1) {
          this.$refs.Theslider.className = "Theslider Thesliders";
          this.$refs.Theslider.style.left = "0px";
          setTimeout(() => {
            this.$refs.Theslider.className = "Theslider";
          }, 100);
        } else {
          this.$refs.Theslider.style.left =
            -this.$refs.caseHeight.offsetWidth * (news - 1) + "px";
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bg {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 1100px) {
  .header2 {
    display: none;
  }
  .header1 {
    position: relative;
    .header-bg {
      width: 100%;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      width:100%;
      // left: 371px;
      top: 423px;
      font-size: 60px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 80px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
      text-align: center;
    }
  }
  .introduce {
    position: relative;
    .video {
      background-color: #a0112d;
      z-index: 1;
      position: absolute;
      top: 0;
      margin: 17% 0;
      width: 100%;
      height: 66%;
    }
    .introduce-bg {
      width: 100%;
    }
    .text {
      position: absolute;
      top: 0;
      margin: 270px 0 0 205px;
      opacity: 0;
      .title {
        p {
          font-size: 60px;
          color: #ffffff;
        }
        div {
          margin-top: 10px;
          width: 71px;
          height: 5px;
          background: #ffffff;
        }
        span {
          display: block;
          margin-top: 20px;
          font-size: 24px;
          color: #ffffff;
        }
      }
      .body {
        width: 968px;
        line-height: 43px;
        letter-spacing: 1px;
        margin-top: 40px;
        font-size: 20px;
        color: #ffffff;
        .br {
          margin-top: 29px;
        }
      }
      .foot {
        margin-top: 80px;
        display: flex;
        align-items: center;

        .list {
          margin-left: 59px;
          display: flex;
          img {
            width: 82px;
            height: 79px;
            margin: 0 25px;
          }
        }
      }
    }
    .logo {
      opacity: 0;
      position: absolute;
      width: 343px;
      height: 79px;
      top: 580px;
      right: 162px;
    }
  }
  .achievement {
    position: relative;
    .achievement-bg {
      width: 100%;
    }
    .main {
      opacity: 0;
      position: absolute;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: 269px;
      margin-left: 198px;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 500px;
        height: 240px;
        p {
          margin-top: 10px;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .box_title {
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    width: 1920px;
    height: 440px;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      width: 100%;
      height: 100%;
      line-height: 440px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 0;
      font-size: 65px;
      font-weight: 500;
      span {
        color: #982037;
      }
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 25px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .service2 {
    display: none;
  }
  .service {
    position: relative;
    img {
      width: 100%;
    }
    .body {
      position: absolute;
      top: 0;
      .left {
        opacity: 0;
        background: url("https://prod.oss.netopstec.com/net-serve-bigxx2.png")
          no-repeat top center;
        background-size: 100% 100%;
        width: 785px;
        height: 758px;
        position: absolute;
        top: 306px;
        left: 210px;
        .circular {
          width: 221px;
          height: 221px;
          position: absolute;
          line-height: 221px;
          font-size: 32px;
          font-weight: 500;
          color: #c71538;
          img {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.92;
          }
          span {
            text-align: center;
            width: 221px;
            height: 221px;
            position: absolute;
            z-index: 1;
          }
        }
        .mid {
          width: 182px;
          font-size: 40px;
          font-weight: 500;
          color: #c71538;
          position: absolute;
          top: 331px;
          left: 309px;
        }
        .circular1 {
          top: 0;
          left: 112px;
        }
        .circular2 {
          top: 0;
          left: 458px;
        }
        .circular3 {
          top: 325px;
          left: 0;
        }
        .circular4 {
          top: 537px;
          left: 285px;
        }
        .circular5 {
          top: 320px;
          left: 564px;
        }
      }
      .right {
        opacity: 0;
        position: absolute;
        top: 444px;
        left: 1157px;
        .title {
          white-space: nowrap;
          font-size: 60px;
          color: #ffffff;
        }
        .gang {
          margin-top: 20px;
          width: 71px;
          height: 5px;
          background: #ffffff;
          margin-bottom: 20px;
        }
        p {
          font-weight: 400;
          color: #ffffff;
          line-height: 43px;
          letter-spacing: 1px;
          margin-top: 40px;
          font-size: 20px;
        }
      }
    }
  }

  .brand {
    position: relative;
    display: flex;
    justify-content: center;
    div {
      // img {
      // opacity: 0;
      width: 170px;
      height: 504px;
      // img {
      //   opacity: 0;
      //   width: 107px;
      //   height: 126px;
      // }
      // }
    }
    
  }
  .brand_rotation {
    position: relative;
    padding-bottom: 16px;
    height: 508px;
    .left {
      width: 51px;
      height: 74px;
      position: absolute;
      top: 40%;
      cursor: pointer;
      left: 10px;
      z-index: 1;
    }
    .right {
      cursor: pointer;
      width: 51px;
      height: 74px;
      position: absolute;
      top: 40%;
      right: 10px;
      z-index: 1;
    }
    .box {
      position: relative;
      margin: 0 22.5rem;
      height: 40rem;
      overflow: hidden;
      .brandLogo{
        position: relative;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        transition: all 1s;
        div {
          display: flex;
          flex-direction: column;
          width: 170px;
          height: 504px;
          padding-left: 15px;
          box-sizing: border-box;
        }
      }
    }
  }
  
  
  .case {
    position: relative;
    .Viewing_area {
      width: 1903px;
      height: 890px;
      overflow: hidden;
      position: relative;
      .Thesliders {
        transition: all 0s !important;
      }
      .Theslider {
        position: absolute;
        top: 0;
        transition: all 1s;
        .brander {
          position: relative;
          .bg {
            cursor: pointer;
            width: 100%;
          }
          .body {
            position: absolute;
            bottom: 150px;
            left: 918px;
            z-index: 1;
            .top {
              display: flex;
              align-items: center;
              .btn {
                color: white;
                margin-left: 50px;
                background: none;
                img {
                  margin-left: 23px;
                }
                &:hover {
                  box-shadow: 0 6px 24px rgb(0 0 0 / 18%);
                }
                &:hover img {
                  animation: mymove 0.5s 1;
                }
              }
            }
            p {
              font-size: 50px;
              font-weight: bold;
              color: #ffffff;
              line-height: 90px;
            }
          }
        }
      }
    }
    .box {
      position: absolute;
      z-index: 1;
      top: 400px;
      right: 30px;
      .dian {
        cursor: pointer;
        margin: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: white;
      }
      .red {
        background-color: #a0112d;
      }
    }
  }
  .dynamic {
    position: relative;
    padding-bottom: 16px;
    height: 800px;
    .left {
      width: 51px;
      height: 74px;
      position: absolute;
      top: 300px;
      cursor: pointer;
      left: 10px;
      z-index: 1;
    }
    .right {
      cursor: pointer;
      width: 51px;
      height: 74px;
      position: absolute;
      top: 300px;
      right: 10px;
      z-index: 1;
    }
    .box {
      padding-top: 50px;
      position: relative;
      margin: 0 200px;
      height: 800px;
      overflow: hidden;
    }
    ul {
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      left: 0;
      transition: all 1s;
    }
    li {
      flex-shrink: 0;
      margin: 0 20px;
      opacity: 0;
      cursor: pointer;
      box-shadow: 0px 0px 18px #c5c6c1;
      list-style: none;
      width: 450px;
      height: 600px;
      &:hover {
        transform: translateY(-10px);
        transition: all 0.3s;
      }
      img {
        width: 450px;
        height: 300px;
      }
      p {
        margin: 108px auto 0;
        width: 350px;
        text-align: center;
        font-size: 25px;
        font-weight: 400;
        color: #000000;
        line-height: 45px;
      }
    }
    .more {
      p {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 270px;
        font-size: 35px;
        font-weight: 400;
        color: #000000;
        line-height: 45px;
        img {
          margin-left: 15px;
          width: 15px;
          height: 21px;
        }
      }
    }
  }
  .foot {
    position: relative;
    h5 {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
    }
    li {
      margin-top: 18px;
      display: flex;
      list-style: none;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 300;
        color: #ffffff;
        line-height: 27px;
      }
    }
    .bg {
      width: 100%;
    }
    .body {
      opacity: 0;
      position: absolute;
      top: 181px;
      left: 238px;
      z-index: 1;
      .logo {
        width: 343px;
        height: 79px;
      }
      .text {
        margin-top: 26px;
        display: flex;
        .left {
          // margin-top: 12px;
          width: 343px;
          p {
            font-size: 18px;
            font-weight: 300;
            color: #ffffff;
            line-height: 30px;
          }
          .erweima {
            width: 150px;
            height: 150px;
            margin-top: 20px;
          }
          h5 {
            margin-top: 28px;
          }
        }
        .right {
          margin-left: 216px;
          display: flex;
          flex-wrap: wrap;
          // float: right;
          .box {
            width: 300px;
            // margin: 0 26px 105px;
            // height: 15px;
          }
        }
      }
      .footer {
        margin-left: 14.3125rem;
        margin-top: 3.6875rem;
        text-align: center;
        width: 58.4375rem;
        font-size: 1.25rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.1875rem;
        p {
          width: 100%;
        }
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  canvas {
    display: none;
  }
  .header1 {
    display: none;
  }
  .header2 {
    display: block;
    position: relative;
    .header-bg {
      width: 750px;
      height: 1179px;
      position: relative;
      z-index: -1;
    }
    h4 {
      position: absolute;
      width: 750px;
      text-align: center;
      top: 506px;
      font-size: 70px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
    h3{
      position: absolute;
      width: 750px;
      text-align: center;
      top: 650px;
      font-size: 66px;
      letter-spacing: 5px;
      font-weight: bold;
      color: #ffffff;
      line-height: 69px;
      text-shadow: 0 0 2px #e5dede;
      animation: bannerLogo 1.5s linear 1;
    }
  }
  .introduce {
    position: relative;
    .video {
      background-color: #a0112d;
      z-index: 1;
      position: absolute;
      top: 0;
      margin: 17% 0;
      width: 100%;
      height: 66%;
    }
    .introduce-bg {
      width: 750px;
      height: 1154px;
    }
    .text {
      width: 750px;
      position: absolute;
      top: 0;
      opacity: 0;
      .title {
        margin-top: 168px;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        p {
          font-size: 60px;
          color: #ffffff;
        }
        div {
          margin-top: 10px;
          width: 71px;
          height: 5px;
          background: #ffffff;
        }
        span {
          display: block;
          margin-top: 20px;
          font-size: 24px;
          color: #ffffff;
        }
      }
      .body {
        width: 650px;
        line-height: 43px;
        letter-spacing: 1px;
        margin-top: 40px;
        padding: 0 50px;
        font-size: 28px;
        text-align: justify;
        color: #ffffff;
        .br {
          margin-top: 29px;
        }
      }
      .foot {
        width: 750px;
        margin-top: 80px;
        display: flex;
        align-items: center;
        .btn {
          position: absolute;
          top: 127px;
          left: 0%;
          right: 0%;
          margin: 0 auto;
        }
        .list {
          width: 750px;
          display: flex;
          justify-content: center;
          img {
            width: 57px;
            height: 56px;
            margin: 0 17px;
          }
        }
      }
    }
    .logo {
      opacity: 0;
      position: absolute;
      width: 343px;
      height: 79px;
      top: 580px;
      right: 162px;
    }
  }
  .achievement {
    display: none;
  }
  .box_title {
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    width: 750px;
    height: 324px;
    h6 {
      color: black;
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 350px 83px;
      width: 100%;
      height: 100%;
      line-height: 324px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 0;
      font-size: 42px;
      font-weight: 500;
      span {
        color: #982037;
      }
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 25px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .service {
    position: relative;
    img {
      width: 750px;
      height: 1143px;
      width: 100%;
    }
    .body {
      width: 750px;
      height: 1143px;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .left {
        opacity: 0;
        background: url("https://prod.oss.netopstec.com/net-serve-bigxx2.png")
          no-repeat top center;
        background-size: 100% 100%;
        width: 543px;
        height: 525px;
        position: absolute;
        top: 520px;
        left: 0;
        right: 0;
        margin: 0 auto;
        .circular {
          width: 149px;
          height: 149px;
          position: absolute;
          line-height: 149px;
          font-size: 23px;
          font-weight: 500;
          color: #c71538;
          img {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.92;
          }
          span {
            text-align: center;
            width: 149px;
            height: 149px;
            position: absolute;
            z-index: 1;
          }
        }
        .mid {
          width: 200px;
          height: 29px;
          font-size: 30px;
          font-weight: 900;
          color: #c71538;
          line-height: 29px;
          position: absolute;
          top: 230px;
          left: 208px;
        }
        .circular1 {
          top: 0;
          left: 80px;
        }
        .circular2 {
          top: 0;
          left: 320px;
        }
        .circular3 {
          top: 224px;
          left: 0;
        }
        .circular4 {
          top: 224px;
          left: 388px;
        }
        .circular5 {
          top: 370px;
          left: 198px;
        }
      }
      .right {
        text-align: center;
        opacity: 0;
        position: absolute;
        top: 176px;
        width: 650px;
        padding: 0 50px;
        .title {
          white-space: nowrap;
          font-size: 60px;
          color: #ffffff;
        }
        .gang {
          margin-top: 20px;
          margin: 20px auto;
          width: 71px;
          height: 5px;
          background: #ffffff;
        }
        p {
          font-weight: 400;
          color: #ffffff;
          line-height: 43px;
          letter-spacing: 1px;
          margin-top: 40px;
          font-size: 28px;
        }
      }
    }
  }
  .brand_rotation {
    position: relative;
    padding-bottom: 16px;
    .left {
      width: 26px;
      height: 38px;
      position: absolute;
      top: 200px;
      cursor: pointer;
      left: 10px;
      z-index: 1;
    }
    .right {
      cursor: pointer;
      width: 26px;
      height: 38px;
      position: absolute;
      top: 200px;
      right: 10px;
      z-index: 1;
    }
    .box {
      position: relative;
      margin: 0 50px;
      height: 30rem;
      overflow: hidden;
      .brandLogo{
        position: relative;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        transition: all 1s;
        div {
          display: flex;
          flex-direction: column;
          width: 170px;
          padding-left: 15px;
          box-sizing: border-box;
          img {
            height:100px !important;
          }
        }
      }
    }
  }
  .case {
    position: relative;
    .Viewing_area {
      width: 750px;
      height: 957px;
      overflow: hidden;
      position: relative;
      .Thesliders {
        transition: all 0s !important;
      }
      .Theslider {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 1s;
        display: flex;
        .brander {
          position: relative;
          .bg {
            cursor: pointer;
            width: 750px;
            height: 957px;
          }
          .body {
            width: 750px;
            height: 957px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            .top {
              transform: scale(0.8);
              position: absolute;
              top: 60px;
              left: 50px;
            }
            .btn {
              position: absolute;
              color: white;
              background: none;
              left: 0;
              right: 0;
              margin: 0 auto;
              bottom: 92px;
              img {
                margin-left: 23px;
              }
              &:hover {
                box-shadow: 0 6px 24px rgb(0 0 0 / 18%);
              }
              &:hover img {
                animation: mymove 0.5s 1;
              }
            }
            p {
              width: 750px;
              text-align: center;
              position: absolute;
              bottom: 176px;
              font-size: 25px;
              font-weight: 400;
              color: #ffffff;
              line-height: 44px;
            }
          }
        }
      }
    }
    .box {
      position: absolute;
      z-index: 1;
      bottom: 45px;
      // right: 30px;
      display: flex;
      width: 750px;
      justify-content: center;
      .dian {
        cursor: pointer;
        margin: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: white;
      }
      .red {
        background-color: #a0112d;
      }
    }
  }
  .dynamic {
    position: relative;
    padding-bottom: 16px;
    height: 400px;
    .left {
      width: 26px;
      height: 38px;
      position: absolute;
      top: 200px;
      cursor: pointer;
      left: 10px;
      z-index: 1;
    }
    .right {
      cursor: pointer;
      width: 26px;
      height: 38px;
      position: absolute;
      top: 200px;
      right: 10px;
      z-index: 1;
    }
    .box {
      padding-top: 50px;
      position: relative;
      margin: 0 50px;
      height: 800px;
      overflow: hidden;
    }
    ul {
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      left: 0;
      transition: all 1s;
    }
    li {
      flex-shrink: 0;
      margin: 0 20px;
      opacity: 0;
      cursor: pointer;
      box-shadow: 0px 0px 18px #c5c6c1;
      list-style: none;
      width: 270px;
      height: 336px;
      // &:hover {
      //   transform: translateY(-10px);
      //   transition: all 0.3s;
      // }
      img {
        width: 270px;
        height: 154px;
      }
      p {
        margin: 37px auto 0;
        width: 209px;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
      }
    }
    .more {
      p {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 130px;
        font-size: 35px;
        font-weight: 400;
        color: #000000;
        line-height: 45px;
        img {
          margin-left: 15px;
          width: 15px;
          height: 21px;
        }
      }
    }
  }
  .foot {
    position: relative;
    h5 {
      font-size: 26px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
    }
    li {
      margin-top: 18px;
      display: flex;
      list-style: none;
      align-items: center;
      // white-space: nowrap;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 10px;
        font-size: 26px;
        font-weight: 300;
        color: #ffffff;
        line-height: 27px;
      }
    }
    .bg {
      width: 100%;
    }
    .body {
      width: 730px;
      opacity: 0;
      position: absolute;
      top: 35px;
      left: 0;
      padding: 0 10px;
      z-index: 1;
      .text {
        margin-top: 26px;
        .right {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .box {
            width: 300px;
            margin: 0 26px 36px;
          }
        }
        .left {
          margin: 50px auto 0;
          width: 343px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            margin: 0 auto;
            font-size: 26px;
            font-weight: 300;
            color: #ffffff;
            line-height: 30px;
          }
          .erweima {
            width: 150px;
            height: 150px;
            margin-top: 20px;
          }
          h5 {
            margin-top: 28px;
          }
        }
      }
      .footer {
        margin-top: 59px;
        text-align: center;
        width: 750px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 35px;
        p {
          width: 100%;
        }
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px){
  #imgStyleMedia {
    height: 126px !important
  }
}
.introduce_ini {
  .logo {
    opacity: 1 !important;
  }
  .text {
    opacity: 1 !important;
    .title {
      animation: introduce_title 0.8s linear 1;
    }
    .body {
      animation: introduce_title 1.6s linear 1;
    }
    .foot {
      animation: introduce_title 2.4s linear 1;
    }
  }
  .logo {
    animation: introduce_title 1.5s linear 1;
  }
}

.achievement_ini {
  .main {
    opacity: 1;
    animation: achievement_ini 1s linear 1;
  }
}

.box_titlea_ini {
  opacity: 1;

  h6 {
    animation: introduce_title 1s linear 1;
  }
}
.box_title2_ini {
  opacity: 1;

  h6 {
    animation: introduce_title 1s linear 1;
  }
}
.box_title3_ini {
  opacity: 1;

  h6 {
    animation: introduce_title 1s linear 1;
  }
}
.box_title4_ini {
  opacity: 1;

  h6 {
    animation: introduce_title 1s linear 1;
  }
}
.box_title5_ini {
  opacity: 1;

  h6 {
    animation: introduce_title 1s linear 1;
  }
}

.service_ini {
  img {
    animation: service_ini 1s linear 1;
    animation-fill-mode: forwards;
  }
  .left {
    animation: service_ini 1s linear 1s 1;
    animation-fill-mode: forwards;
  }
  .right {
    animation: service_ini 1s linear 1s 1;
    animation-fill-mode: forwards;
  }
}

.brand_ini {
  .logo1 {
    animation: service_ini 1s linear 1;
    animation-fill-mode: forwards;
  }
  .logo2 {
    animation: service_ini 1s linear 0.5s 1;
    animation-fill-mode: forwards;
  }
  .logo3 {
    animation: service_ini 1s linear 1s 1;
    animation-fill-mode: forwards;
  }
  .logo4 {
    animation: service_ini 1s linear 1.5s 1;
    animation-fill-mode: forwards;
  }
  .logo5 {
    animation: service_ini 1s linear 2s 1;
    animation-fill-mode: forwards;
  }
  .logo6 {
    animation: service_ini 1s linear 2.5s 1;
    animation-fill-mode: forwards;
  }
  .logo7 {
    animation: service_ini 1s linear 3s 1;
    animation-fill-mode: forwards;
  }
}

.case_ini {
  animation: service_ini 1s linear 1;
  animation-fill-mode: forwards;
}
.btn {
  display: flex;
  width: 250px;
  height: 62px;
  background: #a2112e;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .jt {
    background: url("https://prod.oss.netopstec.com/net-icon-jt.png") no-repeat
      top center;
    background-size: 100% 100%;
    margin-left: 23px;
    width: 12px;
    height: 16px;
  }
  &:hover {
    box-shadow: 0 6px 24px rgb(0 0 0 / 18%);
    background-color: white;
    color: #a2112e;
  }
  &:hover .jt {
    background: url("https://prod.oss.netopstec.com/net-icon-jt2.png") no-repeat
      top center;
    background-size: 100% 100%;
    margin-left: 23px;
    animation: mymove 1s 1;
  }
}

.dynamic_ini {
  li {
    opacity: 1;
  }

  li:nth-child(1) {
    animation: dynamic_ini 1s linear 1;
  }
  li:nth-child(2) {
    animation: dynamic_ini 1.5s linear 1;
  }
  li:nth-child(3) {
    animation: dynamic_ini 2s linear 1;
  }
}

.foot_ini {
  .bg {
    animation: introduce_title 1s linear 1;
  }
  .body {
    animation: introduce_title 1s linear 1s 1;
    animation-fill-mode: forwards;
  }
}
.gdxl {
  width: 78px;
  height: 41px;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  z-index: 1;
  animation: gdxl 2s infinite;
  &:hover {
    content: url("~@/assets/arrow-down.png");
  }    
}
canvas {
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: absolute;
  top: 0;
}

@keyframes mymove {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bannerLogo {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  50% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes introduce_title {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes achievement_ini {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes service_ini {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes dynamic_ini {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  50% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes brand_ini {
  0% {
    // display: block;
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
    opacity: 1;
  }
}

@keyframes nav_li {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes gdxl {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(-10px);
  }
}
</style>
