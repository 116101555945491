

<template>
  <div id="app">
    <navs></navs>
    <router-view ></router-view>
  </div>
</template>

<script>
import navs from './components/navs.vue'
export default {
  components:{navs}
};
</script>

<style>
#app{
  overflow: hidden;
}
@font-face {
  font-family: "siyuan";
  src: url("~@/assets/SourceHanSansCN-Regular.otf");
}
* {
  font-family: 'siyuan';
}
/* 官网悼念色 */
/* html{
    -webkit-filter:grayscale(100%); 
    -moz-filter:grayscale(100%);   
    -ms-filter:grayscale(100%);   
    -o-filter:grayscale(100%);   
    filter:grayscale(100%);        
    filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
} */
</style>
