<template>
  <div>
    <nav class="nav1">
      <img @click="goHome" src="https://prod.oss.netopstec.com/net-logo.png" alt="" />
      <li @click="handleIndex(1, '')">
        <span :class="navindex == 1 ? 'red' : ''">首页</span>
        <div v-if="navindex == 1"></div>
      </li>
      <li @click="handleIndex(2, 'About')">
        <span :class="navindex == 2 ? 'red' : ''">关于网营</span>
        <div v-if="navindex == 2"></div>
      </li>

      <li @click="handleIndex(3, 'newDynamic')">
        <span :class="navindex == 3 ? 'red' : ''">最新动态</span>
        <div v-if="navindex == 3"></div>
      </li>
      <li @click="handleIndex(4, 'service')">
        <span :class="navindex == 4 ? 'red' : ''"
          >服务概览·<b style="">全域营销</b></span
        >
        <div v-if="navindex == 4"></div>
      </li>
      <li @click="handleIndex(5, 'caseShows')">
        <span :class="navindex == 5 ? 'red' : ''">案例展示</span>
        <div v-if="navindex == 5"></div>
      </li>
      <li @click="handleIndex(6, 'cooperation')">
        <span :class="navindex == 6 ? 'red' : ''">商务合作</span>
        <div v-if="navindex == 6"></div>
      </li>
      <li @click="handleIndex(7, 'joinUs')">
        <span :class="navindex == 7 ? 'red' : ''">加入我们</span>
        <div v-if="navindex == 7"></div>
      </li>
    </nav>
    <nav class="nav2">
      <img
      @click="goHome"
        class="logo"
        src="https://prod.oss.netopstec.com/net-logo.png"
        alt=""
      />
      <img class="btn" @click="showUl=!showUl" src="https://prod.oss.netopstec.com/btn-h5.png" alt="" />
      <ul v-if="showUl">
      <li @click="handleIndex(1, '')">
        <span :class="navindex == 1 ? 'red' : ''">首页</span>
      </li>
      <li @click="handleIndex(2, 'About')">
        <span :class="navindex == 2 ? 'red' : ''">关于网营</span>
      </li>

      <li @click="handleIndex(3, 'newDynamic')">
        <span :class="navindex == 3 ? 'red' : ''">最新动态</span>
      </li>
      <li @click="handleIndex(4, 'service')">
        <span :class="navindex == 4 ? 'red' : ''"
          >服务概览·<b style="">全域营销</b></span
        >
      </li>
      <li @click="handleIndex(5, 'caseShows')">
        <span :class="navindex == 5 ? 'red' : ''">案例展示</span>
      </li>
      <li @click="handleIndex(6, 'cooperation')">
        <span :class="navindex == 6 ? 'red' : ''">商务合作</span>
      </li>
      <li @click="handleIndex(7, 'joinUs')">
        <span :class="navindex == 7 ? 'red' : ''">加入我们</span>
      </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navindex: 1,
      showUl:false,
    };
  },
  mounted() {},
  watch: {
    "$route.name": {
      handler(val, oldVal) {
        switch (val) {
          case "Home":
            this.navindex = 1;
            break;
          case "About":
            this.navindex = 2;
            break;
          case "newDynamic":
            this.navindex = 3;
            break;
          case "service":
            this.navindex = 4;
            break;
          case "caseShows":
            this.navindex = 5;
            break;
          case "cooperation":
            this.navindex = 6;
            break;
          case "joinUs":
            this.navindex = 7;
            break;
          default:
            break;
        }
      },
    },
  },
  methods: {
    goHome(){
     this.$router.push({
        path: "/",

      });
    },
    handleIndex(index, router) {
      //标签切换
      this.navindex = index;
      this.$router.push(`/${router}`);
      this.showUl = false
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 1100px) {
  nav {
    background-color: #580e20;
    position: absolute;
    top: 0;
    z-index: 1;
    padding-top: 45px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    img {
      cursor: pointer;
      width: 152px;
      height: 35px;
    }
    li {
      min-width: 71px;
      cursor: pointer;
      list-style: none;
      margin: 0 40px;
      display: flex;
      height: 65px;
      flex-direction: column;
      align-items: center;
      &:hover {
        animation: nav_li 0.5s linear 1;
      }
      span {
        white-space: nowrap;
        letter-spacing: 2px;
        font-size: 20px;
        color: #ffffff;
      }
      .red {
        color: #ffffff;
        font-weight: 900;
      }
      div {
        border-radius: 1px;
        margin-top: 15px;
        width: 35px;
        height: 4px;
        background: #ffffff;
      }
    }
  }
  .nav2 {
    display: none !important;
  }
}
@media screen and (max-width: 1100px) {
  .nav1 {
    display: none;
  }
  .nav2 {
    background-color: #580e20;
    position: fixed;
    top: 0;
    z-index: 1000;
    // padding-top: 45px;
    height: 101px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 750px;
    .logo {
      cursor: pointer;
      margin-left: 48px;
      width: 152px;
      height: 35px;
    }
    .btn {
      width: 40px;
      height: 25px;
      border-radius: 1px;
      margin-right: 52px;
    }
    ul{
     position: absolute;
     top: 100%;
     width: 750px;
     background-color:#580e20;
    }
    li {
      cursor: pointer;
      list-style: none;
      margin: 0 40px;
      display: flex;
      font-size: 28px;
      height: 89px;
      line-height: 89px;
      flex-direction: column;
      align-items: center;
      border-top: 1px solid #fff;
      &:hover {
        animation: nav_li 0.5s linear 1;
      }
      span {
        white-space: nowrap;
        letter-spacing: 2px;
        font-size:  28px;
        color: #ffffff;
      }
      .red {
        color: #ffffff;
        font-weight: 900;
      }
      div {
        border-radius: 1px;
        margin-top: 15px;
        width: 35px;
        height: 4px;
        background: #ffffff;
      }
    }
  }
}
</style>