import request from '@/utils/request'

export function post(url, parameter) {
  return request({
    url: url,
    method: 'post',
    data: parameter
  })
}

export function get(url, parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter
  })
}
export function put(url, parameter) {
  return request({
    url: url,
    method: 'put',
    params: parameter
  })
}

export function filt(url, parameter) {
  return request({
    url: url,
    method: 'get',
    params: parameter,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
export function deleter(url, parameter) {
  return request({
    url: url,
    method: 'delete',
    params: parameter,

  })
}
